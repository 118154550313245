<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      style="background-position: center center !important"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Amchara Gozo, Malta Pricing</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Featured In -->
    <featured-in></featured-in>

    <!-- Premium Pricing -->
    <div class="container">
      <div class="row text-center pt-3 pb-3">
        <div class="col-md-12 mx-auto">
          <div class="row py-3">
            <div class="col-md-12">
              <h2 style="color: orange">Amchara Malta Premium Weeks</h2>

              <h3>Retreats between:</h3>
              <h3>25th March - 31st October</h3>
            </div>
          </div>

          <h4 class="font-weight-bold">Private Room Pricing</h4>

          <div class="row pt-3">
            <div class="col">
              <div class="table-responsive text-center">
                <table class="table info-table info-table-big">
                  <thead>
                    <tr>
                      <th scope="col">Nights</th>
                      <th
                        v-for="p in pricing"
                        :key="'th' + p.id"
                        class="text-capitalize"
                      >
                        {{ p.type }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">3 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>£{{ p.standard_price * 3 + 400 }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>£{{ p.standard_price * 4 + 400 }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>£{{ p.standard_price * 5 + 400 }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>£{{ p.standard_price * 6 + 400 }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>£{{ p.standard_price * 7 + 400 }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8+ nightly rate</th>
                      <td>
                        <p>£200</p>
                      </td>
                      <td>
                        <p>£240</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h4 class="font-weight-bold">Shared Room Pricing</h4>

          <div class="row pt-3">
            <div class="col">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nights</th>
                      <th
                        v-for="p in pricing"
                        :key="'th' + p.id"
                        class="text-capitalize"
                      >
                        {{ p.type }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">3 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>
                          £{{ p.standard_shared_price * 3 + 400
                          }}<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>
                          £{{ p.standard_shared_price * 4 + 400
                          }}<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>
                          £{{ p.standard_shared_price * 5 + 400
                          }}<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>
                          £{{ p.standard_shared_price * 6 + 400
                          }}<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7 nights</th>
                      <td v-for="p in pricing" :key="'td' + p.id">
                        <p>
                          £{{ p.standard_shared_price * 7 + 400
                          }}<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8+ nightly rate</th>
                      <td>
                        <p>
                          £150<small>/pp</small>
                        </p>
                      </td>
                      <td>
                        <p>
                          £170<small>/pp</small>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row text-right">
            <div class="col-md-12">
              <router-link
                to="/uk-pricing"
                class="btn btn-green-gradient btn-sm"
                >Somerset, UK Pricing</router-link
              >
            </div>
          </div>

          <!--  -->
          <div class="row my-3 text-center">
            <div class="col-md-12">
              <p>
                <i
                  >*pp denotes price ‘Per Person’ and is based on two people
                  sharing</i
                >
              </p>
            </div>
          </div>

          <div class="row text-center py-5">
            <div class="col-md-4 mx-auto">
              <router-link to="/enquiries" class="btn btn-orange-gradient"
                >ENQUIRE NOW</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div style="margin-bottom: -3rem" class="mt-0">
      <testimonials></testimonials>
    </div>

    <!-- Newsletter -->
    <div>
      <newsletter-cta></newsletter-cta>
    </div>
  </div>
</template>
<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      pricing: [],
      bgImages: [
        require("@/assets/images/pages/malta-pricing/malta-pricing-header-image.jpg"),
      ],
    };
  },
  methods: {
    fetchPricing() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/../public/rooms/pricing/malta")
        .then(({ data }) => {
          this.pricing = data;
        });
    },
  },
  mounted() {
    this.fetchPricing();
  },
  components: {
    FeaturedIn,
    Testimonials,
    NewsletterCta,
  },
};
</script>

<style></style>
